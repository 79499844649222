<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapActions } from "vuex";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      title: "Add",
      skill: {
        skill_id: null,
        skill_name: null,
        skill_description: " ",
      },
      isSubmitted: false,
      editor: ClassicEditor,
      editorData: "",
      faqeditorData: "",
      content: "<h1>Some initial content</h1>",
      plugins: [
        "advlist autolink link image lists charmap preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor",
      ],
      toolbar:
        "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent fullpage | forecolor backcolor emoticons",
      options: {
        height: 300,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
   
    };
  },
  validations: {
    skill: {
      skill_name: {
        required: helpers.withMessage("Skill Name is required", required),
      },
      skill_description: {
        required: helpers.withMessage("Skill Description is required", required),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
  },
  mounted() {
    // console.log("route", this.$route.name);
    if (this.$route.name == "Editskill" && this.$route.params.id) {

      
      // console.log("route", this.$route.name);
      this.getSkillData(this.$route.params.id);
      this.title = "Update";
    }
  },
  methods: {
    ...mapActions({
      addSkill: "skills/addSkill",
      getSkillById: "skills/getSkillById",
    }),
    clearSkill() {
      this.skill = {
        skill_id: null,
        skill_name: null,
        skill_description: " ",
      };
    },
    saveSkill() {
      this.isSubmitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      
      this.addSkill(this.skill)
        .then((res) => {
          if (res.data) {
            this.isSubmitted = false;
            var msg = this.skill.skill_id
              ? "Skill Updated Successfully"
              : "Skill Added Successfully";
            this.clearSkill();
            this.$toast.open({
              message: msg,
              type: "success",
            });
            this.$router.push({ name: "Skills" });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: "Server Error",
            type: "error",
          });
        });
    },
    getSkillData(skillId) {
      this.getSkillById(skillId)
        .then((res) => {
          console.log("resss", res.data.skill);
          if (res.data.message == 'Skill fetched successfully') {
            let skill = res.data.skill;
            this.skill = {
              skill_id: skill.skills_id,
              skill_name: skill.skill_name,
              skill_description: skill.skill_description,
            };
          } else {
            this.$router.push({ name: "AddSkill" });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader />
    <div class="row">
      <div class="col-xl-12">
        <div class="card">

          <!-- {{ getSkillData }}
          {{ skill }} -->
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">{{ title }} Skill</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 offset-md-3">
                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="skill_name" class="form-label">Skill Name</label>
                  </div>
                  <div class="col-lg-9">
                    <input
                      type="text"
                      class="form-control"
                      id="skill_name"
                  
                      v-model="skill.skill_name"
                      :class="{
                        'is-invalid': isSubmitted && v$.skill.skill_name.$error,
                      }"
                    />
                    <div
                      v-for="(item, index) in v$.skill.skill_name.$errors"
                      :key="index"
                      class="invalid-feedback"
                    >
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="skill_description" class="form-label"
                      >Skill Description</label
                    >
                  </div>
                  <div class="col-lg-9">
                   
                   
                    <!-- <textarea
                      class="form-control"
                      id="skill_description"
                    
                      v-model="skill.skill_description"
                      :class="{
                        'is-invalid': isSubmitted && v$.skill.skill_description.$error,
                      }"
                    ></textarea> -->

                    <ckeditor
                    v-model="skill.skill_description"
                    :editor="editor"
                  ></ckeditor>
                </div>

                    <div
                      v-for="(item, index) in v$.skill.skill_description.$errors"
                      :key="index"
                      class="invalid-feedback"
                    >
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-9 offset-lg-3">
                    <button
                      type="button"
                      class="btn btn-primary mx-3"
                      @click="saveSkill"
                    >
                    Save
                    </button>
                    <router-link :to="{ name: 'Skills' }" class="btn btn-secondary">
                    Cancel
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  </Layout>
</template>
